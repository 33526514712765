import React, {useCallback, useContext, useEffect} from "react";
import {useParams} from "react-router";
import {useHistory} from 'react-router-dom';
import {AuthContext} from "../context/AuthContext";
import {useHttp} from "../hooks/http.hook";
import {Loader} from "../components/Loader";
import config from "../config.json"


const AuthPage = () => {
  const {request} = useHttp()
  const history = useHistory()
  const token = useParams().token
  const auth = useContext(AuthContext);

  const authenticate = useCallback(async () => {
    try {
      const response = await request(config.api + `/check-staff/${token}`);
      if (response.status === 200) {
        auth.login(token, response.data)
        history.push('/dashboard')
      } else {
        history.push('/error')
      }
    } catch (e) {
      history.push('/error')
    }
  },[])

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <Loader/>
  )
}

export default AuthPage
