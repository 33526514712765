import React, {useCallback, useState, useEffect} from 'react'
import {useAuth} from "./hooks/auth.hook";
import {useRoutes} from "./router";
import {AuthContext} from "./context/AuthContext";
import {AudioContext} from "./context/AudioContext";
import {ModalContext} from "./context/ModalContext";
import {TimeContext} from "./context/TimeContext";
import {BrowserRouter as Router} from 'react-router-dom';
import sound from "./audio.mp3";
import Modal from 'react-modal';
import './App.css';
import {useStorage} from "./hooks/storage.hook";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxWidth              : '70%'
  }
};

Modal.setAppElement('#root')

function App() {
  const {token, login, logout, user} = useAuth();
  const audio = new Audio(sound);
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated);
  const [time, setTime] = useState('0')
  const [clicked, setClicked] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [hours, setHours] = useState(0)
  const [session, setSession] = useState(null)
  const [isOpen, setIsOpen] = useState(true)
  const [modalContent, setModalContent] = useState({
    content: 'Привет! Рады приветствовать в нашей студии!',
    title: ''
  })

  const play = useCallback(async () => {
    await audio.play();
  }, [])

  const stop = useCallback(() => {
    audio.stop()
  }, [])

  const open = useCallback((content, title) => {
    setModalContent({content, title})
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])


  const checkTime = () => {
    if (hours >= 10 && hours <= 23 && new Date(session).getDate() !== new Date().getDate()) {
      open(
        <p>
          С началом рабочего дня!
        </p>
      )
      audio.play()
    }

    if (hours === 22) {
      open(
        <p>
          План выполнен - Ты молодец! Мы за сегодняшний день ты заработал 4.000 руб.,
          осталось до конца мес. 56.000 руб.
        </p>
      )
      audio.play()
    }

    const isWorkTime = hours >= 10 && hours <= 23
    if (!isWorkTime) {
      // window.reload()
    }
  }



  const updateTime = useCallback(() => {
    const now = new Date()
    const hoursStr = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
    const minutesStr = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
    setTime(hoursStr+':'+minutesStr)
    setHours(now.getHours())
    setMinutes(now.getMinutes())
    checkTime()
  }, [])

  function startTimers () {
    setInterval(() => {
      updateTime()
    }, 1000)
  }

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setClicked(true)
    })
    startTimers()
  }, [])
  return (
    <AuthContext.Provider value={{
      token,
      user,
      login,
      logout,
      isAuthenticated
    }}>
      <AudioContext.Provider value={{
        ref: audio,
        play,
        stop
      }}>
        <ModalContext.Provider value={{
          isOpen,
          open,
          close
        }}>
          <TimeContext.Provider value={{
            time,
            hours,
            minutes
          }}>
            <Modal
              isOpen={isOpen}
              style={customStyles}
            >
              <div
                className="modal-button"
                onClick={close}
              >X</div>
              <div>
                {modalContent.content}
              </div>
            </Modal>
            {
              clicked ?
                (
                  <Router>
                    {routes}
                  </Router>
                ) : null
            }
          </TimeContext.Provider>
        </ModalContext.Provider>
      </AudioContext.Provider>
    </AuthContext.Provider>

  );
}

export default App;
