import {useCallback} from 'react';

export const useStorage = () => {
  const setKey = useCallback((key, data) => {
    localStorage.setItem(key, data)
  }, [])

  const getKey = (key) => {
    return localStorage.getItem(key);
  }

  const removeKey = useCallback((key) => {
    localStorage.removeItem(key)
  }, [])

  return {getKey, setKey, removeKey}
}