import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import Dashboard from "./pages/Dashboard";
import ErrorPage from "./pages/ErrorPage";
import LoaderPage from "./pages/LoaderPage";
import TimeoutPage from "./pages/TimeoutPage";


export const useRoutes = isAuthenticated => {
  const isWorkTime = new Date().getHours() >= 10 && new Date().getHours() <= 23

  // if (!isWorkTime) {
  //   return (
  //     <Switch>
  //       <Route path="/timeout" component={TimeoutPage} />
  //       <Redirect to='/timeout' />
  //     </Switch>
  //   )
  // }

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Redirect to='/dashboard' />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/auth/:token" component={AuthPage} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/loader" component={LoaderPage} />
      <Redirect to='/loader' />
    </Switch>
  );
};
