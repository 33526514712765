import React from 'react';

export const Loader = ({mini}) => {
  return (
    <div className={(mini ? 'small' : null) + " loader"}>
      <div className={(mini ? 'small' : 'big') + " preloader-wrapper active"}>
        <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>,
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
