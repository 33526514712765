import React from 'react';

const ErrorPage = () => {
  return (
    <div style={{textAlign: 'center', margin: '0 auto', maxWidth: '1200px'}} className="row">
      <h5>При попытки авторизации возникла ошибка! Обратитесь к администратору</h5>
    </div>
  );
}

export default ErrorPage;
