 import React, {useEffect, useState} from "react";

const Form = props => {
  const {setData} = props
  const [form, setForm] = useState({
    sum: '',
    count: ''
  })

  useEffect(() => {
    window.M.updateTextFields();
  });


  const changeHandler = e => {
    const number = e.target.value.replace(/\D/g, '');
    setForm({
      ...form,
      [e.target.name]: number
    })
  }

  const submitForm = event => {
    event.preventDefault()
    if (form.sum !== '' || form.count !== '')
      setData(form);
      setForm({sum: '', count: ''})

  }

  return (
    <form onSubmit={submitForm}>
      <div className="input-field">
        <input
          placeholder="Введите сумму"
          id="sum"
          type="text"
          name="sum"
          value={form.sum}
          onChange={changeHandler}
        />
        <label className="black-text" htmlFor="sum">Желаемый заработок</label>
      </div>


      <label className="black-text" htmlFor="count">Количество смен</label>
      <input
        placeholder="Количество смен"
        id="count"
        name="count"
        type="text"
        value={form.count}
        onChange={changeHandler}
      />

      <button
        type="submit"
        className="btn main-color waves-effect black-text"
        style={{
          color: 'inherit !important'
        }}
      >Подтвердить</button>
    </form>
  )
}

export default Form
