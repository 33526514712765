import React, {useCallback, useContext, useEffect, useState} from 'react';
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Content from "../components/Content/Content";
import {AuthContext} from "../context/AuthContext";
import {Loader} from "../components/Loader";

const Dashboard = () => {
  const auth = useContext(AuthContext);

  if (!auth.user)
    return <Loader/>

  return (
    <div>
      <Navbar name={auth.user.name}/>
      <div style={{margin: '0 auto', maxWidth: '1200px'}} className="row">
        <div className="dashboard-content">
          <div className="col s5">
            <Content />
          </div>
          <div className="col s7">
            <Sidebar/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
