import React, {useCallback, useContext, useEffect, useState} from "react"
import Popup from "reactjs-popup";
import Form from "../Form";
import styles from './Content.module.css'
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/http.hook";
import config from "../../config.json";
import {useStorage} from "../../hooks/storage.hook";
import {ModalContext} from "../../context/ModalContext";
import {AudioContext} from "../../context/AudioContext";
import {Loader} from "../Loader";

const month = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

const Content = () => {
  const auth = useContext(AuthContext);
  const audio = useContext(AudioContext);
  const modal = useContext(ModalContext);
  const {getKey, setKey} = useStorage()
  const {request} = useHttp()
  const [ready, setReady] = useState(false)
  const [goal, setGoal] = useState({})
  const [monthF, setMonthF] = useState({
    money: 0,
    days: 0
  })
  const [profit, setProfit] = useState({
    goal: 0,
    fact: 0
  })
  const [services, setServices] = useState({
    goal: 0,
    fact: 0
  })
  const [cosmetics, setCosmetics] = useState({
    goal: 0,
    fact: 0
  })

  const fetchData = useCallback(async () => {
    const nowDate = new Date('2021-03-30');
    const goals = await request(config.api + '/goals/' + auth.user.yc_user_id)
    const nowGoal = goals.data.goals.filter(goal => new Date(goal.created_at).getMonth() === new Date().getMonth())

    const sales = await request(config.api + '/sales/' + auth.user.yc_user_id)
    const arr = []
    for (let key in sales.data) {
      arr.push(sales.data[key]);
    }

    const todayDate = `${nowDate.getFullYear()}-${nowDate.getMonth()+1}-${nowDate.getDate()}`
    const todays = await request(config.api + '/records/' + auth.user.yc_user_id +
      `?start_date=${todayDate}&end_date=${todayDate}&count=2000`)


    const startDate = `${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()-4}`
    const response = await request(config.api + '/records/' + auth.user.yc_user_id +
      `?start_date=${startDate}&end_date=${todayDate}&count=2000`)

    return {
      goal: nowGoal[0],
      cosmetics: arr,
      today: todays.data,
      records: response.data
    }
  }, [request])

  const calculate = useCallback(data => {
    // console.log(data)
    // {
    //   goal: nowGoal[0],
    //     cosmetics: arr,
    //   today: todays.data,
    //   records: response.data
    // }

    //  ------------------------------ Цель на март -------------------------------
    setGoal(data.goal)
    //  ------------------------------ Факт на март -------------------------------
    let factSum = 0
    let smen = []
    data.records.forEach(record => {
      const date = record.date.split(' ')[0]
      if (!smen.includes(date)) {
        smen.push(date)
      }

      record.services.forEach(service => {
        factSum += service.manual_cost
      })
    })

    const tempMonthF = {
      days: smen.length,
      money: (factSum.toFixed() / 100 * 20).toFixed()
    }
    //  ------------------------------ Доход за смену -------------------------------
    // Расчет плана / факта заработка на день
    const sumPerDay = Number(data.goal.goal) - tempMonthF.money
    let workDay = Number(data.goal.count_workdays) - tempMonthF.days <= 0 ? 1 :
      Number(data.goal.count_workdays) - tempMonthF.days
    let factProfit = 0
    data.today.forEach(record => {
      if (record.attendance === 1) {
        record.services.forEach(service => {
          factProfit += service.manual_cost
        })
      }
    })
    const tempProfit = {
      fact: (factProfit / 100 * 20).toFixed(),
      goal: (sumPerDay / workDay).toFixed()
    }

    //  ------------------------------ Оказать услуг на сумму -------------------------------
    // Расчет плана / факта по услугам
    setServices({
      fact: (factProfit * 0.95).toFixed(),
      goal: (tempProfit.goal / 20 * 95).toFixed()
    })

    //  ------------------------------ Продать косметики на сумму -------------------------------
    // Расчет план / факта по косметике
    let sumProducts = 0
    data.cosmetics.forEach(cosmetic => {
      sumProducts += cosmetic.cost
    })



    setCosmetics({
      fact: (sumProducts * 0.2).toFixed() ,
      goal: (tempProfit.goal / 20 * 5).toFixed()
    })
    setMonthF(tempMonthF)
    setProfit(tempProfit)
    setReady(true)
  }, [])


  const updateData = useCallback(async data => {
    await request(
      config.api + '/goals',
      'POST',
      {
        staff_id: auth.user.yc_user_id,
        goal: data.sum,
        count_workdays: data.count
      }
    )
    await fetchData()
  }, [fetchData, request, auth])

  const checkSession = () => {
    const session = getKey('Session_start')
    if (!session) {
      modal.open(
        <p>
          О, отлично! Делай услуг на ___ руб. и косметики на ___. руб.! Мы в тебя верим!
        </p>
      )
      audio.play()
    } else if (new Date(session).getDate() !== new Date().getDate()) {
      modal.open(
        <p>
          О, отлично! Делай услуг на ___ руб. и косметики на ___. руб.! Мы в тебя верим!
        </p>
      )
      audio.play()
    }
    setKey('Session_start', new Date().toDateString())
  }

  useEffect(async () => {
    setReady(false)
    const data = await fetchData()

    checkSession()
    calculate(data)
  }, [])


  return (
    <>
      {
        goal ?
          <>
            <h5>
              <b>Цель на {month[new Date().getMonth()]}:</b><br/>
              {!ready ? <Loader mini/> : `${goal.goal} руб. / ${goal.count_workdays} смен` }
            </h5>
            <h5>
              <b>Факт за {month[new Date().getMonth()]}:</b><br/>
              {!ready ? <Loader mini/> : `${monthF.money} руб. / ${monthF.days} смен` }
            </h5>
            <br/>
            <p className={styles.title}>Ваш план на день:</p>
            <table>
              <thead>
                <tr>
                  <th>Показатель</th>
                  <th>План</th>
                  <th>Факт</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>доход за смену</td>
                  <td>{!ready ? <Loader mini/> : `${profit.goal} руб.` }</td>
                  <td>{!ready ? <Loader mini/> : `${profit.fact} руб.` }</td>
                </tr>
                <tr>
                  <td>оказать услуг на сумму</td>
                  <td>{!ready ? <Loader mini/> : `${services.goal} руб.` }</td>
                  <td>{!ready ? <Loader mini/> : `${services.fact} руб.` }</td>
                </tr>
                <tr>
                  <td>продать косметики на сумму</td>
                  <td>{!ready ? <Loader mini/> : `${cosmetics.goal} руб.` }</td>
                  <td>{!ready ? <Loader mini/> : `${cosmetics.fact} руб.` }</td>
                </tr>
              </tbody>
            </table>
          </> :
          <>
            <h5>Цель не установлена</h5>
          </>
      }

      <Popup
        open={!goal}
        closeOnEscape={false}
        closeOnDocumentClick={false}
        modal
        nested
        className="goal-popup"
      >
        <Form
          setData={updateData}
        />
      </Popup>
    </>
  )
}

export default Content
