import React, {useContext} from "react"
import {AuthContext} from "../context/AuthContext";
import {TimeContext} from "../context/TimeContext";

const Navbar = props => {
  const auth = useContext(AuthContext);
  const time = useContext(TimeContext);
  const {name} = props

  return (
    <div className="main-color shadow navbar">
      <p className="header-title">
        <span>Здравствуйте, <b>{name}</b>!</span>
        <span>Время: {time.time}</span>
        <span>Рабочий день - 10:00 до 23:00</span>
        <span style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} onClick={auth.logout}>
          <i className="material-icons">exit_to_app</i>
          Выйти
        </span>
      </p>
    </div>
  )
}

export default Navbar
