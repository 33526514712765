import React from "react";
import styles from './ClientCard.module.css'

const ClientCard = props => {
  const {data} = props
  const attendances = ['Клиент не пришел', 'Ожидание клиента', 'Клиент пришел', 'Клиент подтвердил']

  if (data.client && data.client.name)
    return (
      <div className="main-color rounded shadow">
        <ul className="collection with-header">
          <li className={styles.title}>
            <div>
              {data.client.name}
            </div>
            <div>
              <span data-badge-caption={attendances[data.attendance + 1]} className='new badge'> </span>
            </div>
          </li>
          {
            data.services.map((service, index) => {
              return (
                <li className={styles.item + " collection-item"} key={index}>
                  <i className="material-icons">local_atm</i>
                  {service.title} - {service.manual_cost} руб.
                </li>
              )
            })
          }
        </ul>
      </div>
    )

  return (
    <div className="main-color rounded shadow">
      <ul className="collection with-header">
        <li className={styles.title}>
          <div>
            Данные о клиенте заполнены не правильно
          </div>
          <div>
            <span data-badge-caption={"Ошибка"} className='new badge'> </span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default ClientCard
