import React, {useCallback, useEffect} from 'react';
import {Loader} from "../components/Loader";
import {useHistory} from "react-router-dom";

const LoaderPage = () => {
  const history = useHistory()

  const load = useCallback( () => {
    setTimeout(() => {
      history.push('/error')
    }, 5000)
  },[history])

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Loader />
  );
}

export default LoaderPage;
