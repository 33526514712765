import {useState, useCallback, useEffect} from 'react';
import {useHttp} from "./http.hook";
import config from "../config.json";

const storageKey = 'AUTH_KEY';

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const {request} = useHttp()

  const login = useCallback((token, user) => {
    setToken(token);
    setUser(user);
    localStorage.setItem(storageKey, JSON.stringify({token, ...user}))
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem(storageKey)
  }, []);

  useEffect(async () => {
    const data = JSON.parse(localStorage.getItem(storageKey));
    if (!data)
      return

    const response = await request(config.api + `/check-staff/${data.token}`);
    if (data && response.status === 200) {
      login(data.token, response.data);
    }
  }, [login, request]);

  return {logout, login, user, token};
};
