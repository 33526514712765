import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'materialize-css/dist/css/materialize.min.css'
import 'reactjs-popup/dist/index.css';
import 'materialize-css'

ReactDOM.render(<App />,
  document.getElementById('root')
);
