import React, {useCallback, useContext, useEffect, useState} from "react"
import ClientCard from "../ClientCard/ClientCard";
import styles from './Sidebar.module.css'
import config from "../../config.json";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import {AudioContext} from "../../context/AudioContext";
import {Loader} from "../Loader";
import {ModalContext} from "../../context/ModalContext";

const attendances = ['Клиент не пришел', 'Ожидание клиента', 'Клиент пришел', 'Клиент подтвердил']

const Sidebar = () => {
  const [records, setRecords] = useState([])
  const auth = useContext(AuthContext);
  const audio = useContext(AudioContext);
  const modal = useContext(ModalContext)
  const {request} = useHttp()

  const getClients = useCallback(async () => {
    try {
      const nowDate = new Date('2021-03-30');
      const dataStr = `${nowDate.getFullYear()}-${nowDate.getMonth()+1}-${nowDate.getDate()}`
      const response = await request(config.api + '/records/' + auth.user.yc_user_id +
        `?start_date=${dataStr}&end_date=${dataStr}&count=1000`)
      return response.data.reverse()
    } catch (e) {}
  }, [request])

  const calculateAverage = useCallback(() => {
    let sum = 0
    if (records && records.length > 0) {
      records.forEach(record => {
        record.services.forEach(service => {
          sum += service.manual_cost
        })
      })
      return Number(sum / records.length).toFixed()
    }
    return 0
  },[records])

  const checkRecords = useCallback(async () => {
    const nowDate = new Date();
    const dataStr = `${nowDate.getFullYear()}-${nowDate.getMonth()+1}-${nowDate.getDate()}`
    const response = await request(config.api + '/records/' + auth.user.yc_user_id +
      `?start_date=${dataStr}&end_date=${dataStr}&count=1000`)
    let message = []
    response.data.forEach(newRecord => {
      records.forEach(oldRecord => {
        if (newRecord.id === oldRecord.id) {
          if (newRecord.attendance !== oldRecord.attendance) {
            message.push({
              name: newRecord.client.name,
              status: attendances[newRecord.attendance + 1]
            })
            audio.play()
          }
        }
      })
    })
    if (message.length > 0) {
      modal.open(
        <>
          {message.map((updated, index) => {
            return (
              <p key={index}>
                Изменился статус у клиента <b>{updated.name}</b> на : <b>{updated.status}</b>
              </p>
            )
          })}
        </>
      )
      setRecords(response.data.reverse());
      audio.play()
    }
  }, [request, records])

  useEffect(async () => {
    const data = await getClients()
    setRecords(data)
    setInterval(async () => {
      await checkRecords()
    }, 1000 * 60 * 5)
  }, [])

  if (records && !records.length) {
    return (
      <div className="rounded shadow side-bar">
        <Loader/>
      </div>
    )
  }

  return (
    <div className="rounded shadow side-bar">
      {
        <>
          <p className={styles.title}>Клиенты на день ({records.length} чел.)</p>
          <p>Средний чек услуги : {calculateAverage()} руб.</p>
          <br/>
          {
            records.map((record, index) => <ClientCard key={index} data={record}/>)
          }
        </>
      }
    </div>
  )
}

export default Sidebar
